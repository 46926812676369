var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-end flex-wrap",attrs:{"id":`${_vm.db.saveField}${_vm.db.objectId ? _vm.db.objectId : ''}`}},[_c('div',{staticClass:"field-wrapper",class:_vm.args.inputClass},[(_vm.args.label)?_c('label',{staticClass:"sub-label"},[_vm._v(_vm._s(_vm.args.label))]):_vm._e(),_c('b-input-group',[(_vm.args.prependText)?_c('b-input-group-prepend',[_c('b-input-group-text',[_vm._v(" "+_vm._s(_vm.args.prependText)+" ")])],1):_vm._e(),_c('masked-input',{staticClass:"form-control standard-input",class:{'is-invalid' : _vm.errorsPost.length==true},attrs:{"guide":false,"mask":_vm.mask ? _vm.mask : [
                      /[0]/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                  ],"placeholder":_vm.args.placeholder,"type":_vm.args.type,"state":_vm.serverErrors.length || _vm.errorsPost.length ? false : null,"disabled":_vm.skipped,"placeholderChar":"#"},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),(_vm.args.appendText)?_c('b-input-group-append',[_c('b-input-group-text',[_vm._v(" "+_vm._s(_vm.args.appendText)+" ")])],1):_vm._e()],1)],1),(_vm.args.skippable)?_c('div',{staticClass:"add-or flex-shrink-0"},[_c('label',{staticClass:"fancy-checkbox-container"},[_vm._v("Skip "),_c('input',{attrs:{"type":"checkbox"},domProps:{"value":_vm.skipped,"checked":_vm.skipped ? 'checked' : null},on:{"click":_vm.skipQuestion}}),_c('span',{staticClass:"checkmark"})])]):_vm._e(),(_vm.args.otherFunction)?_c('div',{staticClass:"add-or flex-shrink-0"},[_c('label',{staticClass:"fancy-checkbox-container"},[_vm._v(_vm._s(_vm.args.otherFunction.text || "Other Function")+" "),_c('input',{attrs:{"type":"checkbox"},on:{"click":function($event){return _vm.$emit('otherFunction')}}}),_c('span',{staticClass:"checkmark"})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }