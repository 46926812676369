<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputSelect
        v-model="data"
        :options="optionsLender"
        :db="db"
        :args="args"
        :skipped="skipped"
        :noSave="noSave"
        :js-errors="jsErrors"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { conveyanceOptions } from "../../../options/conveyanceOptions";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputSelect from "@/components/common/questionnaires/question/inputs/InputSelect";

export default {
  name: 'MortgageLender',
  mixins: [conveyanceOptions, questionLoadHelpers],
  components: {
    InputSelect,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Who is mortgage lender?',
        subTitle:  null,
        tip: null,
      },
      db: {
        saveLocation: `conveyance_quote?code=${this.$route.params.code}`,
        saveField: 'purchase_mortgage_lender',
      },
      args: {
        taggable: false,
        label: null,
        placeholder: 'Please select..',
        skippable: true,
        required: true,
        inputClass: 'field-66'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>