<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Introduction</h3>

    <Actions
        v-model="form.actions"
    />
    <div class="question-box with-arrow" v-if="form.action==='Re-mortgage'">
      <ReMortgageAction
          v-model="form.remortgage_action"
      />
    </div>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

// import SaleQuotes from "@/components/common/questionnaires/question/questions/conveyance/SaleQuotes";
import Actions from "@/components/common/questionnaires/question/questions/conveyance/introduction/Actions";
import ReMortgageAction from "@/components/common/questionnaires/question/questions/conveyance/introduction/ReMortgageAction";

export default {
  name: 'Introduction',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    Actions,
    ReMortgageAction,
  },
  data() {
    return {
      label: 'Transaction', // used for steps progress
      disableStepsNavigation: false,
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
  },
};
</script>
