<template>
  <InputAddress
      v-model="data"
      :db-override="db"
      :question-override="question"
      :jsErrorsData="jsErrors"
      :noSave="noSave"
      :errors-post="errorsPost"
      @jsErrors="jsErrors=$event"
  />
</template>

<script>
import InputAddress from "@/components/common/questionnaires/question/inputs/InputAddress";

export default {
  name: 'Address',
  components: {
    InputAddress
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      required: true
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'What is the address of the property being sold?'
      },
      db: {
        saveLocation: `sale_address?code=${this.$route.params.code}`,
        saveField: 'sale_address',
        deleteLocation: `sale_address?code=${this.$route.params.code}`,
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: this.$parent.$parent.$data.show
    }
  }
}
</script>
