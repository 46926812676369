<template>
  <QuestionBase
      :question="question"
      :valid="!!(valueFirst || valueSecond)"
      :errors="errors"
  >
    <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
      <div class="question question-multipart field-50">
        <InputBasic
            :value="valueFirst"
            @input="$emit('inputFirst', $event)"
            ref="email"
            :args="firstInputOptions"
            :db="db.first"
            :skipped="skipped"
            :no-save="noSave"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.email=$event"
            @jsErrors="jsErrors=$event"
        />

      </div>
      <div class="question question-multipart field-50">
        <InputMasked
            :value="valueSecond"
            @input="$emit('inputSecond', $event)"
            ref="phone_number"
            :args="secondInputOptions"
            :db="db.second"
            :skipped="skipped"
            :no-save="noSave"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.phone_number=$event"
            @jsErrors="jsErrors=$event"
        />
      </div>
    </div>
  </QuestionBase>
</template>

<script>
import {skipHelpers} from "../../helpers/skipHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputBasic from "../../../inputs/InputBasic";
import InputMasked from "../../../inputs/InputMasked";

export default {
  name: 'ContactInformation',
  components: {
    InputBasic,
    InputMasked,
    QuestionBase
  },
  mixins: [skipHelpers, questionLoadHelpers],
  props: {
    valueFirst: {
      type: String,
      required: false
    },
    valueSecond: {
      type: String,
      required: false
    },
    objectId: {
      type: [Number, String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPostFirst: {
      type: Array,
      default: () => {
        return []
      }
    },
    errorsPostSecond: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed: {
    errors() {
      return [...this.serverErrors.phone_number, ...this.serverErrors.email, ...this.jsErrors]
    }
  },
  data() {
    return {
      question: {
        title: 'Email Address and/or Phone Number?',
        subTitle: 'Please enter e-mail address and phone number',
        tip: null
      },
      firstInputOptions: {
        placeholder: 'e.g. example@example.com',
        label: 'Email:',
        type: 'text',
      },
      secondInputOptions: {
        placeholder: 'e.g. 0797238715',
        label: 'Phone Number:',
        type: 'text',
      },
      db: {
        saveLocation: 'conveyance_quote_person',
        saveField: 'email',
        first: {
          saveLocation: 'conveyance_quote_person',
          saveField: 'email',
          objectId: this.objectId
        },
        second: {
          saveLocation: 'conveyance_quote_person',
          saveField: 'phone_number',
          objectId: this.objectId
        },
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: {
        email: [],
        phone_number: [],
      },
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>