<template>
  <div>
    <InputRadio
        v-model="data"
        :db="db"
        :no-save="noSave"
        :options="options"
        :skipped="skipped"
        :errors-post="errorsPost"
        :js-errors="jsErrors"
        :args="args"
        @skipped="$emit('skipped', $event)"
        @serverErrors="$emit('serverErrors', $event)"
        @jsErrors="$emit('jsErrors', $event)"
        @other="$emit('other')"
        class="radio-options-wide"
    >
    </InputRadio>
  </div>
</template>

<script>

import InputRadio from "./InputRadio";

export default {
  /* client input with optional other select (used in assets) */
  name: 'InputConveyancers',
  components: {InputRadio},
  props: {
    value: {
      type: Number,
      required: false
    },
    valueOther: {
      type: Boolean,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    db: {
      type: Object,
      required: true
    },
    dbOther: {
      type: Object,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    jsErrors: {
      type: Array,
      default: () => {
        return []
      }
    },
    optionsData: {
      type: Array,
      default: () => {
        return []
      }
    },
    skipped: {
      type: Boolean,
      required: false
    },
    args: {
      type: Object,
      required: true
    },
    showOther: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    dataOther: {
      set(value) {
        this.$emit('inputOther', value)
      },
      get() {
        return this.valueOther
      }
    },
    options () {
      return this.optionsData.map(item => {
        return {
          label: item.firm,
          value: item.id
        }
      })
    }
  },
}
</script>