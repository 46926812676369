<template>
  <div
    :id="`${db.saveField}${db.objectId ? db.objectId : ''}`"
    class="d-flex align-items-end flex-wrap"
  >
    <div class="field-wrapper" :class="args.inputClass">
      <label v-if="args.label" class="sub-label">{{ args.label }}</label>
      <b-input-group>
        <b-input-group-prepend v-if="args.prependText">
          <b-input-group-text>
            {{ args.prependText }}
          </b-input-group-text>
        </b-input-group-prepend>

        <masked-input v-model="data"
                      :class="{'is-invalid' : errorsPost.length==true}"
                      :guide="false"
                      :mask="mask ? mask : [
                        /[0]/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                    ]"
                    :placeholder="args.placeholder"
                    :type="args.type"
                    :state="serverErrors.length || errorsPost.length ? false : null"
                    :disabled="skipped"
                    class="form-control standard-input"
                      placeholderChar="#"
                      />


        <b-input-group-append v-if="args.appendText">
          <b-input-group-text>
            {{ args.appendText }}
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div v-if="args.skippable" class="add-or flex-shrink-0">
      <label class="fancy-checkbox-container"
        >Skip
        <input
          type="checkbox"
          :value="skipped"
          :checked="skipped ? 'checked' : null"
          @click="skipQuestion"
        />
        <span class="checkmark"></span>
      </label>
    </div>

    <div v-if="args.otherFunction" class="add-or flex-shrink-0">
      <label class="fancy-checkbox-container"
        >{{ args.otherFunction.text || "Other Function" }}
        <input type="checkbox" @click="$emit('otherFunction')" />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { skipHelpers } from "../questions/helpers/skipHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";
import MaskedInput from "vue-text-mask";

export default {
  name: "InputMasked",
  mixins: [saveHelpers, skipHelpers],
  components: {
    MaskedInput
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    db: {
      type: Object,
      required: true,
    },
    args: {
      type: Object,
      required: true,
    },
    skipped: {
      type: Boolean,
      required: false,
    },
    noSave: {
      type: Boolean,
      default: false,
    },
    errorsPost: {
      type: Array,
      default: () => {
        return [];
      },
    },
    formatter: {
      type: Function,
      required: false,
    },
    mask: {
      type: String,
      required: false,
    },

  },
  data() {
    return {
      serverErrors: [],
      jsErrors: [],
    };
  },
  computed: {
    data: {
      set(value) {
        this.$emit("input", value);
        if (this.noSave) return;
        this.$nextTick(() => {
          this.save();
        });
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>