<template>
  <QuestionBase :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :args="args"
                :class="'radio-options-wide'"
                :db="db"
                :errors-post="errorsPost"
                :js-errors="jsErrors"
                :no-save="noSave"
                :options="conveyanceTenureOptions"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {conveyanceOptions} from "../../../options/conveyanceOptions";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";

export default {
  name: 'Tenure',
  mixins: [
    questionLoadHelpers,
    conveyanceOptions
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What is the property Tenure?',
        subTitle: 'Most houses are Freehold and most flats are Leasehold, though this is not always the case.',
        tip: null
      },
      db: {
        saveLocation: `conveyance_quote?code=${this.$route.params.code}`,
        saveField: 'sale_tenure',
      },
      args: {
        taggable: true,
        label: null,
        placeholder: 'Please select..',
        skippable: false,
        required: true,
        inputClass: 'field-75'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>