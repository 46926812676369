<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Confirm Instruction</h3>

    <ProceedYN
        v-model="form.proceed"
    />

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

// import SaleQuotes from "@/components/common/questionnaires/question/questions/conveyance/SaleQuotes";
import ProceedYN from "@/components/common/questionnaires/question/questions/conveyance/confirm_instruction/ProceedYN";

export default {
  name: 'ConfirmInstruction',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    ProceedYN,
  },
  data() {
    return {
      label: 'Confirm Instruction', // used for steps progress
      disableStepsNavigation: false,
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
  },
};
</script>
