<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Additional Information</h3>

    <template v-if="form.actions.includes('Sell')&&form.sale_address">
      <h3><i class="i-Home1 mr-2"/> Sale of {{ form.sale_address.line_1 }}, {{ form.sale_address.postcode }}</h3>

      <SaleMortgageYN
          v-model="form.sale_mortgage"
      />
      <div class="question-box with-arrow" v-if="form.sale_mortgage">
        <SaleMortgageLender
            v-model="form.sale_mortgage_lender"
        />

        <SaleMortgageOutstand
            v-model="form.sale_mortgage_outstand"
        />
      </div>

      <SaleEstateAgentYN
          v-model="form.sale_estate_agent"
      />

      <div class="question-box with-arrow" v-if="form.sale_estate_agent">
        <SaleEstateAgentAddress
            v-model="form.sale_estate_agent_address"
            :form="form"
        />

        <SaleEstateAgentContactPerson
            v-model="form.sale_estate_agent_contact_person"
        />

        <SaleEstateAgentContactNumber
            v-model="form.sale_estate_agent_contact_number"
        />

        <SaleEstateAgentContactEmail
            v-model="form.sale_estate_agent_contact_email"
        />
      </div>

      <SaleNotes
          v-model="form.sale_notes"
      />

      <h4><i class="i-MaleFemale mr-2"/> Sellers</h4>

      <Sellers v-model="form.sellers" @refresh="$emit('refreshConveyance')" ref="sellers"/>


    </template>
    <hr>
    <template v-if="form.actions.includes('Buy')&&form.purchase_address">
      <h3><i class="i-Home1 mr-2"/> Purchase of {{ form.purchase_address.line_1 }}, {{ form.purchase_address.postcode }}
      </h3>

      <PurchaseDepositPreliminary
          v-model="form.purchase_deposit_preliminary"
      />

<!--            <PurchaseDepositAgreed-->
<!--                v-model="form.purchase_deposit_agreed"-->
<!--            />-->

      <PurchaseCompletionDate
          v-model="form.purchase_completion_date"
      />

      <div class="question-box with-arrow" v-if="form.purchase_completion_date === 'Specific Date'">
        <PurchaseCompletionDateDate v-if="form.purchase_completion_date!='No Deadline'"
                                    v-model="form.purchase_completion_date_date"
        />
      </div>

      <PurchaseMortgageYN
          v-model="form.purchase_mortgage"
      />
      <div class="question-box with-arrow" v-if="form.purchase_mortgage">
        <PurchaseMortgageLender
            v-model="form.purchase_mortgage_lender"
        />
        <PurchaseMortgageOutstand
            v-model="form.purchase_mortgage_outstand"
        />
      </div>

      <PurchaseEstateAgentYN
          v-model="form.purchase_estate_agent"
      />
      <div class="question-box with-arrow" v-if="form.purchase_estate_agent">
        <PurchaseEstateAgentAddress
            v-model="form.purchase_estate_agent_address"
            :form="form"
        />
        <PurchaseEstateAgentContactPerson
            v-model="form.purchase_estate_agent_contact_person"
        />
        <PurchaseEstateAgentContactNumber
            v-model="form.purchase_estate_agent_contact_number"
        />
        <PurchaseEstateAgentContactEmail
            v-model="form.purchase_estate_agent_contact_email"
        />
      </div>

      <PurchaseNotes
          v-model="form.purchase_notes"
      />

      <h4><i class="i-MaleFemale mr-2"/> Buyers</h4>

      <Buyers v-model="form.buyers" @refresh="$emit('refreshConveyance')" ref="buyers"/>

    </template>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import SaleMortgageYN from "@/components/common/questionnaires/question/questions/conveyance/sale/MortgageYN";
import SaleMortgageLender from "@/components/common/questionnaires/question/questions/conveyance/sale/MortgageLender";
import SaleMortgageOutstand
  from "@/components/common/questionnaires/question/questions/conveyance/sale/MortgageOutstand";
import SaleEstateAgentYN from "@/components/common/questionnaires/question/questions/conveyance/sale/EstateAgentYN";
import SaleEstateAgentAddress
  from "@/components/common/questionnaires/question/questions/conveyance/sale/EstateAgentAddress";
import SaleEstateAgentContactPerson
  from "@/components/common/questionnaires/question/questions/conveyance/sale/EstateAgentContactPerson";
import SaleEstateAgentContactNumber
  from "@/components/common/questionnaires/question/questions/conveyance/sale/EstateAgentContactNumber";
import SaleEstateAgentContactEmail
  from "@/components/common/questionnaires/question/questions/conveyance/sale/EstateAgentContactEmail";
import SaleNotes from "@/components/common/questionnaires/question/questions/conveyance/sale/Notes";

import PurchaseDepositPreliminary
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/DepositPreliminary";

import PurchaseMortgageYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/MortgageYN";
import PurchaseMortgageLender
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/MortgageLender";
import PurchaseMortgageOutstand
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/MortgageOutstand";
import PurchaseEstateAgentYN
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/EstateAgentYN";
import PurchaseEstateAgentAddress
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/EstateAgentAddress";
import PurchaseEstateAgentContactPerson
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/EstateAgentContactPerson";
import PurchaseEstateAgentContactNumber
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/EstateAgentContactNumber";
import PurchaseEstateAgentContactEmail
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/EstateAgentContactEmail";
import PurchaseNotes from "@/components/common/questionnaires/question/questions/conveyance/purchase/Notes";
import PurchaseCompletionDate
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/CompletionDate";
import PurchaseCompletionDateDate
  from "@/components/common/questionnaires/question/questions/conveyance/purchase/CompletionDateDate";


import Sellers from "@/components/common/questionnaires/question/questions/conveyance/Sellers";
import Buyers from "@/components/common/questionnaires/question/questions/conveyance/Buyers";

export default {
  name: 'ExtraInformation',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    SaleMortgageYN,
    SaleMortgageLender,
    SaleMortgageOutstand,
    SaleEstateAgentYN,
    SaleEstateAgentAddress,
    SaleEstateAgentContactPerson,
    SaleEstateAgentContactNumber,
    SaleEstateAgentContactEmail,
    SaleNotes,
    PurchaseDepositPreliminary,
    PurchaseMortgageYN,
    PurchaseMortgageLender,
    PurchaseMortgageOutstand,
    PurchaseEstateAgentYN,
    PurchaseEstateAgentAddress,
    PurchaseEstateAgentContactPerson,
    PurchaseEstateAgentContactNumber,
    PurchaseEstateAgentContactEmail,
    PurchaseNotes,
    PurchaseCompletionDate,
    PurchaseCompletionDateDate,
    Sellers,
    Buyers,
  },
  data() {
    return {
      label: 'Additional Information', // used for steps progress
      disableStepsNavigation: false,
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
  },
};
</script>
