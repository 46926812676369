<template>

  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <div class="question-box mt-40">
      <div class="question-box-intro-two mb-30">
        <h3>Would you like to proceed with quote?</h3>
      </div>

      <p><strong>By selecting 'yes' written instructions will be sent to the solicitors.</strong></p>
      <InputRadio
          v-model="data"
          :options="booleanYesNo"
          :db="db"
          :args="args"
          :skipped="skipped"
          @skipped="skipped=$event"
          @serverErrors="serverErrors=$event"
          @jsErrors="jsErrors=$event"
          ref="input"
      />
    </div>
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { general } from "../../../options/general";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";

export default {
  name: 'ProceedYN',
  mixins: [general, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },

  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  null,
        subTitle:  null,
        tip: null,
      },
      db: {
        saveLocation: `conveyance_quote_proceed?code=${this.$route.params.code}`,
        saveField: 'terms_and_conditions',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>