<template>
  <div class="main-content-wrap d-flex flex-column">
    <QuestionnaireBase :title="title" :form="form" :finishFunction="finishFunction"
    :showNavigationButtons="!completed"
    >
      <template v-slot:default  v-if="!completed">
        <component :is="show" :key="1" :ref="show" v-model="form" @refreshConveyance="fetchConveyance"
        @direct_download="direct_download"
        />
        <component
            v-if="load in $options.components &&  !completed"
            v-show="false"
            :is="load"
            key="load"
            :ref="load"
            v-model="form"
        />
      </template>
      <template v-if="completed">
            <Complete v-model="form" :key="999" />
        </template>
    </QuestionnaireBase>
  </div>
</template>

<script>
import {conveyanceHelpers} from "@/views/questionnaires/Conveyance/conveyanceHelpers";
import {quoteHelpers} from "@/views/questionnaires/Conveyance/quoteHelpers";

import QuestionnaireBase from "@/components/common/questionnaires/questionnaire";
import Introduction from "@/components/common/questionnaires/fieldsets/conveyance/Introduction";
import Options from "@/components/common/questionnaires/fieldsets/conveyance/Options";
import Quote from "@/components/common/questionnaires/fieldsets/conveyance/Quote";
import ExtraInformation from "@/components/common/questionnaires/fieldsets/conveyance/ExtraInformation";
import ConfirmInstruction from "@/components/common/questionnaires/fieldsets/conveyance/ConfirmInstruction";
import Complete from "@/components/common/questionnaires/fieldsets/conveyance/Complete";

export default {
  name: "Conveyance",
  mixins: [conveyanceHelpers, quoteHelpers],
  components: {
    QuestionnaireBase,
    Introduction,
    Options,
    Quote,
    ExtraInformation,
    ConfirmInstruction,
    Complete,
  },
  data() {
    return {
      completed:false,
      name: 'Conveyancing Quotation',
      title: "Conveyancing Quotation",
      order: [ // imported components required here to load
        'Introduction',
        'Options',
        'Quote',
        'ExtraInformation',
        'ConfirmInstruction',
      ],
      expiryAccessCodeOnFinish: true,
      noInitialBackButton: true,
      finishFunction: this.complete,
    };
  },
  mounted() {
    console.log(this.$route.path)
  },
  methods:{
    // itemTotal(item){
    //   return item.fees.reduce((partialSum, a) => partialSum + a.value, 0)
    // },
    async complete(){
      const dataCombined={
        url:`conveyance_quote_notify?code=${this.$route.params.code}`,
        quoteDetails: this.form,
      }
      await this.notify(dataCombined);
      // this.$emit("notify",form);
    },
  },
};
</script>
