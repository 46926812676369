import { http } from '@/services'
import { httpQuestionnaire } from '@/services'
import { blankForms } from "@/components/common/questionnaires/question/helpers/blankForms";
import { toast } from '@/mixins/toast'
import { dateFormat } from "@/mixins/dateFormat";
import {quoteHelpers} from "@/views/questionnaires/Conveyance/quoteHelpers";

export const conveyanceHelpers = {
  mixins: [toast, blankForms, dateFormat, quoteHelpers],
  watch: {
    '$route.params.code': {
      immediate: true,
      handler(val) {
        if (!val) {
          this.initConveyance()
        }
        else {
          this.fetchConveyance(true);
        }
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch('resetQuestionnaire')
  },
  data() {
    return {
      form: {},
      show: null,
      load: null,
    }
  },
  methods: {
    fetchConveyance(setQuestions = false) {
      httpQuestionnaire.get('conveyance_quote', { params: { code: this.$route.params.code } }).then(
        response => {
          this.initPrices();
          this.form = response.data
          if (!this.form.sellers) {
            this.form.sellers = [];
          }
          if (!this.form.buyers) {
            this.form.buyers = [];
          }
          if (setQuestions) {
            this.setQuestions();
          }
        }
      ).catch(
        error => {
          console.log(error)
        }
      )
    },
    initConveyance() {
      httpQuestionnaire.post('conveyance_quote').then(
        response => {
          this.$router.push({ name: 'Conveyance', params: { code: response.data.code } })
        }
      ).catch(
        error => {
          console.log(error)
        }
      )
    },

    initPrices() {
      httpQuestionnaire.get('price_list').then(
        response => {
          this.form.price_list = response.data

          // if there is just one conveyancer set as selected conveyancer
          if (this.form.price_list.length == 1) {
            this.form.firm = this.form.price_list[0].id;
          }

        }
      ).catch(
        error => {
          console.log(error)
        }
      )
    },

    async setQuestions() {
      // load questions into dom in order to set store questions properties for progress calculations

      for (var i = this.order.length - 1; i >= 0; i--) {
        this.show = this.order[i]

        // wait for components to load before moving to next fieldset
        await this.timeout(70)
      }

      this.show = this.order[0]
      await this.timeout(70)

      // load first fieldset
      this.$nextTick(() => {
        this.$store.commit('questionsLoaded', true)
      })
    },
    async timeout(ms) {
      // pass a time in milliseconds to this function
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    direct_download(form) {
      let data = {
        include_header: 'header',
        html: this.generateHtml(form.quoteDetails, form.selected_quote),
      };

      http
        .post(form.url, data, { responseType: 'arraybuffer' })
        .then((response) => {
          this.openFile(response, 'quote.pdf');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openFile(response, filename) {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], {
        type: type,
        encoding: 'UTF-8'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      link.remove();
    },
    generateHtml(quoteDetails, selected_quote) {
      return `
     <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <title>Conveyancing Estimate</title>

   ${this.css()}
</head>

<body>

<div>${this.sectionHeading('Conveyancing Instruction')}
    <div class="d-flex flex-wrap mb-30">
        <div class="flex-grow-1 w-100">
          <table class="striped">
            <tr>
            <td class="text-14 font-weight-700 w-25">Legal fees</td>
            <td class="w-75">£${selected_quote.total}</td>
            </tr>
            <tr>
              <td class="text-14 font-weight-700 w-25">VAT</td>
              <td class="w-75">£${selected_quote.vat}</td>
            </tr>
            <tr>
              <td class="text-14 font-weight-700 w-25">Estimate Total</td>
              <td class="w-75">£${selected_quote.total + selected_quote.vat}</td>
            </tr>
          <table>
        </div>
    </div>
</div>

<p>&nbsp;</p>

<div>${this.sectionHeading('Conveyancer Details')}
    <div class="d-flex flex-wrap mb-30">
        <div class="flex-grow-1 w-100">
          <table class="striped">
          <tr>
            <td class="text-14 font-weight-700 w-25">Conveyancer</td>
            <td class="w-75">${selected_quote.firm}</td>
          </tr>
          <tr>
            <td class="text-14 font-weight-700 w-25">Email</td>
            <td class="w-75">${selected_quote.email}</td>
          </tr>
          <table>
        </div>
    </div>
</div>

<p>&nbsp;</p>

<div class="no-brake">${this.sectionHeading('Estimate Detail and Breakdown')}
    <div class="d-flex flex-wrap mb-30">
      <div class="flex-grow-1 w-100">
        ${this.saleQuote(selected_quote, quoteDetails)}
      </div>
    </div>
    <div class="d-flex flex-wrap mb-30">
      <div class="flex-grow-1 w-100">
      ${this.purchaseQuote(selected_quote, quoteDetails)}
      </div>
    </div>
</div>

<p>&nbsp;</p>

<div class="no-brake">${this.sectionHeading('Additional Information')}
    <div class="d-flex flex-wrap mb-30">
        <div class="flex-grow-1 w-100">
        ${this.saleExtra(quoteDetails)}
        ${this.sellers(quoteDetails)}
        <p>&nbsp;</p>
        ${this.purchaseExtra(quoteDetails)}
        ${this.buyers(quoteDetails)}
        </div>
    </div>
</div>


<div>${this.sectionHeading('Terms and Conditions')}
  <div class="d-flex flex-wrap mb-30">
  <div class="flex-grow-1 w-100">
Dunham McCarthy's referral facility offers competitive, transparent, and comprehensive estimates for services related to selling, purchasing, remortgaging, or transferring property and land. Our estimates include all of the standard legal costs that will be incurred, and we want to make sure that you are aware that there is no industry format for legal quotations and that you should carefully check that all standard costs and fees have been included in any other quotations you may be considering. Furthermore, our conveyancer firms do not charge extra for completing the Stamp Duty Land Tax Return or for a mortgage lender being involved.

<h2 class="mb-30 mt-30">Searches</h2>
<p>Our quotes include a Personal Local Authority, Personal Water/Drainage, Environmental and Chancel Repair Liability Search for each property. However, depending on the location of the property, additional searches may be necessary. For example, if the property is in an area affected by mining activities, we will advise you on the extra searches that may be required. 
We have committed to being transparent in our quotations, however, it is not possible to anticipate every eventuality. In some cases, additional disbursement costs (from 3rd parties) may be incurred. This is usually due to extra searches being needed due to the property's location, certain lenders not accepting search indemnity insurance, an indemnity policy being needed to make up for a defect, or charges from the freeholder and/or management company for leasehold properties. If VAT, Land Registry Fees, or any other disbursement costs change before they are due to be paid, this is out of our control and you will need to pay the revised amount.
</p>
<h2 class="mb-30 mt-3030">Land Registry Fees</h2>
<p>Except for New Build and Right to Buy properties, the figure quoted overleaf for Land Registry Fees is based on the assumption that the property being purchased has already been registered with HM Land Registry. If the property has never been registered, the conveyancer will charge an extra fee for unregistered title.
The fee quoted is based on the assumption that the conveyancer can use the Land Registry's fee scale for electronic registration. However, if electronic registration is not possible, the fee charged will be double as the Land Registry's fee scale for postal registration must be used. Your law firm will let you know if postal registration is necessary.
</p>
<h2 class="mb-30 mt-30">Confidentiality</h2>
<p>Dunham McCarthy is committed to protecting your personal information and will not disclose it to anyone other than the conveyancing firm that is handling your transaction and the Introducer who referred you, so that they can provide you with the best service.
</p>
  </div>
  </div>
</div>

</body>

</html>
      `;
    },
    css() {
      return `<style type="text/css">
    
    
    :root {
      font-size: 16px;
    }
    
    .new-page {
      page-break-before: always !important;
    }
    
    /*html {*/
    /*  white-space: nowrap;*/
    /*}*/
    
    body, p {
      text-align: justify;
      white-space: normal;
      font-family: "Times New Roman", Georgia, Serif;
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.1rem;
      display: block;
      margin: 0;
    }
    
    *, :after, :before {
      box-sizing: border-box;
    }
    
    h1 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 40px;
    }
    
    h2 {
      font-weight: 600;
      font-size: 0.9rem;
      margin-top: 40px;
      margin-bottom: 20px;
      text-decoration: underline
    }
    
    p {
      margin-bottom: 10px;
    }
    .no-brake {
      page-break-inside: avoid !important;
      white-space: nowrap;
    }
    .object-heading {
      border-bottom: 2px solid #663069 !important;
    }
    
    .section-heading {
      border-bottom: 2px solid #a58e50 !important;
    }

    .text-17 {
      font-size: 17px;
    }
    
    .text-15 {
      font-size: 15px;
    }
    
    .text-14 {
      font-size: 14px;
    }
    
    .text-right {
    text-align: right;
    }
    
    .d-inline-block {
      display: inline-block
    }
    
    .font-weight-700 {
      font-weight: 600;
    }
    
    .mb-30 {
      margin-bottom: 30px
    }
    
    .w-25 {
      width: 25%;
      flex-basis: 25%;
    }
    
    .w-50 {
      width: 50%;
      flex-basis: 50%;
    }
    
    .w-100 {
      width: 100%;
      flex-basis: 100%;
    }
    
    .w-33 {
      width: 33%;
      flex-basis: 33%;
    }
    
    .d-flex {
      display: flex;
    }
    
    .flex-wrap {
      flex-wrap: wrap;
    }
    
    .no-wrap {
      white-space: nowrap;
    }
    
    table {
      width: 100%;
      font-size: .7rem;
      line-height: 1.1;
    }
    
    th {
      border-bottom: 2px solid #a58e50;
      margin-top: 30px;
    }
    
    .striped > tbody > tr:nth-of-type(even) {
      background-color: #fafafa;
    }
    
    .striped > tbody > tr:nth-of-type(odd) {
      background-color: #ffffff;
    }
    
    td {
      padding: 0.2em 0.5em;
    }
    
    .vertical-top{
      vertical-align:top
    }
    </style>`;
    },
    quoteGen(quoteDetails) {
      let string = '';
      let salequotes = quoteDetails.filter(q => q.type == 'Sale');
      let purchasequotes = quoteDetails.filter(q => q.type == 'Purchase');
      if (salequotes && salequotes.length) {
        salequotes.forEach(s => {
          if (s.address && s.price) {
            string += `<h2>${s.type} of ${s.address.line_1}</h2>`;
            string += '<p class="text-14 mb-24">';
            string += `<strong> ${s.address.line_1},</strong><br/>`;
            if (s.address.line_2) {
              string += `<span>${s.address.line_2},<br/></span>`;
            }
            if (s.address.town) {
              string += `<span>${s.address.town},<br/></span>`;
            }
            if (s.address.post_town) {
              string += `<span>${s.address.post_town},<br/></span>`;
            }
            if (s.address.county) {
              string += `<span>${s.address.county},<br/></span>`;
            }
            string += `<span>${s.address.postcode}<br/></span>`;
            string += '</p>';

            string += '<p class="text-14 mb-24">';
            string += '<strong>Price</strong><br/>';
            string += `<span>£${s.price}</span>`;
            string += '</p>';

            if (s.tenure) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>Tenure</strong><br/>';
              string += `<span>£${s.tenure}</span>`;
              string += '</p>';
            }

            if (s.new != null) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>New</strong><br/>';
              if (s.new === true) {
                string += '<span>YES</span>';
              }
              if (s.new === false) {
                string += '<span>NO</span>';
              }
              string += '</p>';
            }

            if (s.new != null && s.htb != null) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>Help-to-Buy</strong><br/>';
              if (s.htb === true) {
                string += '<span>YES</span>';
              }
              if (s.htb === false) {
                string += '<span>NO</span>';
              }
              string += '</p>';
            }

            if (s.mortgage != null) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>Mortgage</strong><br/>';
              if (s.mortgage === true) {
                string += `<span>YES ${s.mortgage_lender ? ` - ${s.mortgage_lender}` : ''}</span>`;
              }
              if (s.mortgage === false) {
                string += '<span>NO</span>';
              }
              string += '</p>';
            }

            if (s.estate_agent != null) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>Mortgage</strong><br/>';
              if (s.estate_agent === true) {
                string += `<span>YES ${s.estate_agent_name ? ` - ${s.estate_agent_name}` : ''}</span>`;
              }
              if (s.estate_agent === false) {
                string += '<span>NO</span>';
              }
              string += '</p>';
            }

            string += '<table class="table table-condensed w-100 mt-24">';
            s.fees.forEach(fee => {
              string += `<tr><th>${fee.label}</th><td class="text-right">£${fee.value}</td></tr>`;
            })
            string += `<tr style="border-top:#ddd 1px solid"><th>Total</th><th class="text-right">£${s.total}</th></tr>`;
            string += '</table>';
          }
        })
      }

      if (purchasequotes && purchasequotes.length) {
        purchasequotes.forEach(s => {

          if (s.address && s.price) {
            string += `<h2>${s.type} of ${s.address.line_1}</h2>`;
            string += '<p class="text-14 mb-24">';
            string += `<strong> ${s.address.line_1},</strong><br/>`;
            if (s.address.line_2) {
              string += `<span>${s.address.line_2},<br/></span>`;
            }
            if (s.address.town) {
              string += `<span>${s.address.town},<br/></span>`;
            }
            if (s.address.post_town) {
              string += `<span>${s.address.post_town},<br/></span>`;
            }
            if (s.address.county) {
              string += `<span>${s.address.county},<br/></span>`;
            }
            string += `<span>${s.address.postcode}<br/></span>`;
            string += '</p>';

            string += '<p class="text-14 mb-24">';
            string += '<strong>Price</strong><br/>';
            string += `<span>£${s.price}</span>`;
            string += '</p>';

            if (s.tenure) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>Tenure</strong><br/>';
              string += `<span>£${s.tenure}</span>`;
              string += '</p>';
            }

            if (s.new != null) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>New</strong><br/>';
              if (s.new === true) {
                string += '<span>YES</span>';
              }
              if (s.new === false) {
                string += '<span>NO</span>';
              }
              string += '</p>';
            }

            if (s.new != null && s.htb != null) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>Help-to-Buy</strong><br/>';
              if (s.htb === true) {
                string += '<span>YES</span>';
              }
              if (s.htb === false) {
                string += '<span>NO</span>';
              }
              string += '</p>';
            }

            if (s.mortgage != null) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>Mortgage</strong><br/>';
              if (s.mortgage === true) {
                string += `<span>YES ${s.mortgage_lender ? ` - ${s.mortgage_lender}` : ''}</span>`;
              }
              if (s.mortgage === false) {
                string += '<span>NO</span>';
              }
              string += '</p>';
            }

            if (s.estate_agent != null) {
              string += '<p class="text-14 mb-24">';
              string += '<strong>Mortgage</strong><br/>';
              if (s.estate_agent === true) {
                string += `<span>YES ${s.estate_agent_name ? ` - ${s.estate_agent_name}` : ''}</span>`;
              }
              if (s.estate_agent === false) {
                string += '<span>NO</span>';
              }
              string += '</p>';
            }

            string += '<table class="table table-condensed w-100 mt-24">';
            s.fees.forEach(fee => {
              string += `<tr><th>${fee.label}</th><td class="text-right">£${fee.value}</td></tr>`;
            })
            string += `<tr style="border-top:#ddd 1px solid"><th>Total</th><th class="text-right">£${s.total}</th></tr>`;
            string += '</table>';
          }

        })
      }

      return string;
    },
    async notify(dataCombined) {
      this.completed = true

      const selectedQuota = this.quoteFeeDetails.filter(q => q.id === dataCombined.quoteDetails.firm)[0]

      let data = {
        include_header: 'header',
        html: selectedQuota ? this.generateHtml(dataCombined.quoteDetails, selectedQuota): "",
      };

      return http
        .post(dataCombined.url, data)
        .then(() => {
          console.log('notified')
          return true
        })
        .catch((error) => {
          console.log(error);
          return false
        });
    },
    sectionHeading(title) {
      let string = '<table>';
      string += `<tr><td class="text-17 font-weight-700 section-heading">${[
        title
      ]}</td>`;
      string += `</tr></table>`;
      return string;
    },
    formatAddress(address){
      let result = "";
      if (address.line_1) {
        result += `${address.line_1} `;
      }
      if (address.line_2) {
        result += `${address.line_2} `;
      }
      if (address.town) {
        result += `${address.town} `;
      }
      if (address.post_town) {
        result += `${address.post_town} `;
      }
      if (address.county) {
        result += `${address.county} `;
      }
      if (address.postcode) {
        result += `${address.postcode} `;
      }

      return result;
    },
    sellers(form) {
      if (form.actions.includes('Sell') && form.sellers) {
        let result = '<table>'

        result += `<tr>
                <td class="w-50 font-weight-700">Sellers</td>
                <td class="w-50">${form.sellers.map(s => `${s.first_name} ${s.last_name} ${s.email ? ` - ${s.email}` : ''} ${s.phone ? ` - ${s.phone}` : ''} ${s.address ? ` - ${this.formatAddress(s.address)}` : ''} `).join('<br>')}</td>
                </tr>`

        result += '</table>'
        return result
      }
      return ''
    },
    buyers(form) {
      if (form.actions.includes('Buy') && form.buyers) {
        let result = '<table>'

        result += `<tr>
                <td class="w-50 font-weight-700">Buyers</td>
                <td class="w-50">${form.buyers.map(s => `${s.first_name} ${s.last_name} ${s.email ? ` - ${s.email}` : ''} ${s.phone ? ` - ${s.phone}` : ''} ${s.address ? ` - ${this.formatAddress(s.address)}` : ''}`).join('<br>')}</td>
                </tr>`

        result += '</table>'
        return result
      }
      return ''
    },
    saleQuote(item, quoteDetails) {
      if (item.sale_fees) {
        return `
            <table>
              <tr>
                <td class="w-50 text-18 font-weight-700">Sale Estimate</td>
                <td class="w-50 text-18 font-weight-700">Transaction Details</td>
              </tr>
              <tr>
                <td class="w-50">
                    ${this.formatFeeItems(item.sale_fees)}
                </td>
                <td class="w-50 vertical-top">
                    ${this.saleTransactionDetails(quoteDetails)}
                </td>
              </tr>
  
            <table>
            `
      }
      return ''
    },
    purchaseQuote(item, quoteDetails) {
      if (item.purchase_fees) {
        return `
            <table>
              <tr>
                <td class="w-50 text-18 font-weight-700">Purchase Estimate</td>
                <td class="w-50 text-18 font-weight-700">Transaction Details</td>
              </tr>
              <tr>
                <td class="w-50">
                  ${this.formatFeeItems(item.purchase_fees)}
                </td>
                <td class="w-50 vertical-top">
                  ${this.purchaseTransactionDetails(quoteDetails)}
                </td>
              </tr>
  
            <table>
            `
      }
      return ''
    },
    saleExtra(form) {
      if (form.actions.includes('Sell') && form.sale_address) {
        let result = '<table>'

        result += `<tr>
                <td colspan=2 class="text-18 font-weight-700">Sale of ${form.sale_address.line_1}, ${form.sale_address.postcode}</td>
                </tr>
                <tr>
                <td class="w-50 font-weight-700">Is the property mortgaged?</td>
                <td class="w-50">${form.sale_mortgage}</td>
                </tr>`

        if (form.sale_mortgage) {
          result += `<tr>
            <td class="w-50 font-weight-700">Who is the mortgage lender?</td>
            <td class="w-50">${form.sale_mortgage_lender}</td>
            </tr>
            <tr>
            <td class="w-50 font-weight-700">What is the total amount of outstanding mortgage debt secured against the property?</td>
            <td class="w-50">£${form.sale_mortgage_outstand}</td>
            </tr>`
        }

        result += `<tr>
          <td class="w-50 font-weight-700">Is the property being sold through an estate agent?</td>
          <td class="w-50">${form.sale_estate_agent}</td>
          </tr>`

        if (form.sale_estate_agent) {
          result += `<tr>
            <td class="w-50 font-weight-700">What is the name / address of the estate agent?</td>
            <td class="w-50">${form.sale_estate_agent_address.line_1} ${form.sale_estate_agent_address.postcode}</td>
            </tr>
            <tr>
            <td class="w-50 font-weight-700">Is there a particular person dealing with the sale at the agency?</td>
            <td class="w-50">${form.sale_estate_agent_contact_person}</td>
            </tr>
            <tr>
            <td class="w-50 font-weight-700">What is the contact number of the estate agent?</td>
            <td class="w-50">${form.sale_estate_agent_contact_number}</td>
            </tr>
            <tr>
            <td class="w-50 font-weight-700">Is there an email address for the estate agency?</td>
            <td class="w-50">${form.sale_estate_agent_contact_email}</td>
            </tr>`
        }

        if (form.sale_notes) {
          result += `<tr>
            <td class="w-50 font-weight-700">Notes</td>
            <td class="w-50">${form.sale_notes}</td>
            </tr>`
        }

        result += '</table>'
        return result
      }
      return ''
    },
    purchaseExtra(form) {
      if (form.actions.includes('Buy') && form.purchase_address) {
        let result = '<table>'

        result += `<tr>
                    <td colspan=2 class="text-18 font-weight-700">Purchase of ${form.purchase_address.line_1}, ${form.purchase_address.postcode}</td>
                    </tr>
                    <tr>
                    <td class="w-50 font-weight-700">How much money are you providing toward the purchase of the property?</td>
                    <td class="w-50">${form.purchase_deposit_preliminary}</td>
                    </tr>
                    <tr>
                    <td class="w-50 font-weight-700">Is there a set completion deadline?</td>
                    <td class="w-50">${form.purchase_completion_date}</td>
                    </tr>`

        if (form.purchase_completion_date_date) {
          result += `<tr>
                <td class="w-50 font-weight-700">What is the completion deadline?</td>
                <td class="w-50">${form.purchase_completion_date_date}</td>
                </tr>`
        }

        result += `<tr>
              <td class="w-50 font-weight-700">Is a mortgage being obtained on the property?</td>
              <td class="w-50">${form.purchase_mortgage}</td>
              </tr>`
        if (form.purchase_mortgage) {
          result += `<tr>
                <td class="w-50 font-weight-700">Who is mortgage lender?</td>
                <td class="w-50">${form.purchase_mortgage_lender}</td>
                </tr>
                <tr>
                <td class="w-50 font-weight-700">How much will you be borrowing?</td>
                <td class="w-50">${form.purchase_mortgage_outstand}</td>
                </tr>`
        }

        result += `<tr>
              <td class="w-50 font-weight-700">Are you purchasing the property through an estate agent?</td>
              <td class="w-50">${form.purchase_estate_agent}</td>
              </tr>`
        if (form.purchase_estate_agent) {
          result += `<tr>
                <td class="w-50 font-weight-700">What is the name / address of the estate agent?</td>
                <td class="w-50">${form.purchase_estate_agent_address.line_1} ${form.purchase_estate_agent_address.postcode}</td>
                </tr>
                <tr>
                <td class="w-50 font-weight-700">Is there a particular person dealing with the sale at the agency?</td>
                <td class="w-50">${form.purchase_estate_agent_contact_person}</td>
                </tr>
                <tr>
                <td class="w-50 font-weight-700">What is the contact number of the estate agent?</td>
                <td class="w-50">${form.purchase_estate_agent_contact_number}</td>
                </tr>
                <tr>
                <td class="w-50 font-weight-700">Is there an email address for the estate agency?</td>
                <td class="w-50">${form.purchase_estate_agent_contact_email}</td>
                </tr>`
        }

        if (form.purchase_notes) {
          result += `<tr>
                <td class="w-50 font-weight-700">Notes</td>
                <td class="w-50">${form.purchase_notes}</td>
                </tr>`
        }

        result += '</table>'
        return result

      }
      return ''
    },
    formatFeeItems(fees) {
      let string = '<table class="striped">';
      fees.forEach(x => {
        string += `<tr><td>${x.label}</td><td class='text-right'>£${x.value}</td></tr>`;
      })
      string += `<tr><td class='font-weight-700'>Total</td><td class='font-weight-700 text-right'>£${fees.reduce((partialSum, a) => partialSum + a.value, 0)}</td></tr>`;
      string += `</table>`;
      return string;
    },
    saleTransactionDetails(quoteDetails) {
      let string = '<table class="striped">';
      string += `<tr><td class='font-weight-700'>Transaction Type</td><td class='font-weight-700'>Sale Property</td></tr>`;
      string += `<tr><td>Postcode</td><td>${quoteDetails.sale_address.postcode}</td></tr>`;
      string += `<tr><td>Value</td><td>£${quoteDetails.sale_price}</td></tr>`;
      string += `<tr><td>Tenure</td><td>${quoteDetails.sale_tenure}</td></tr>`;
      string += `<tr><td>Existing Mortgage</td><td>${quoteDetails.sale_mortgage ? 'Yes' : 'No'}</td></tr>`;
      if (quoteDetails.sale_mortgage && quoteDetails.sale_mortgage_lender) {
        string += `<tr><td>Lender</td><td>${quoteDetails.sale_mortgage_lender}</td></tr>`;
      }
      string += `</table>`;
      return string;
    },
    purchaseTransactionDetails(quoteDetails) {
      let string = '<table class="striped">';
      string += `<tr><td class='font-weight-700'>Transaction Type</td><td class='font-weight-700'>Purchase Property</td></tr>`;
      string += `<tr><td>Postcode</td><td>${quoteDetails.purchase_address.postcode}</td></tr>`;
      string += `<tr><td>Value</td><td>£${quoteDetails.purchase_price}</td></tr>`;
      string += `<tr><td>Tenure</td><td>${quoteDetails.purchase_tenure}</td></tr>`;
      string += `<tr><td>Existing Mortgage</td><td>${quoteDetails.purchase_mortgage ? 'Yes' : 'No'}</td></tr>`;
      if (quoteDetails.purchase_mortgage && quoteDetails.purchase_mortgage_lender) {
        string += `<tr><td>Lender</td><td>${quoteDetails.purchase_mortgage_lender}</td></tr>`;
      }
      string += `</table>`;
      return string;
    },
    tc() {
      // THIS SECTION WILL BE GENERATED DYNAMIC...
      let string = '<table class="w-100"><tr><td>';
      string += '<p>When purchasing a property, your Conveyancing Search Pack includes a Personal Local Authority Search, Regulated Private Drainage and Water Search and Environmental Search.</p>';
      string += '<p>Dependent on the area in which you are purchasing your property, other recommended searches may be required, but this will be advised by your conveyancer.</p>';
      string += '<p>In the event of a transaction not completing, the conveyancer agrees to offer a “no completion no fee” service and not charge the client any conveyancing legal fees for the abortive transaction. Where subsequent transactions do not complete for the same client, it is at the conveyancer\'s discretion whether to offer “no completion no fee” service for those transactions. This clause does not include refunding amounts paid for disbursements.</p>';
      string += '<p>If any additional work is required as a result of a request from your lender or due to the geographical location of the property in question (which could not have been foreseen at the time of providing an illustration) then this work will be discussed with you and quoted for by the firm.</p>';
      string += '<p>The price for the legal work assumes that this will not prove to be substantially more complex than would normally be expected on a residential sale, purchase or remortgage. If for example there are defects in title or other significant problems of a time-consuming nature, your chosen solicitor or licensed conveyancer, will inform you in writing of the estimated cost of this additional work with a view to agreeing an amended charge with you.</p>';
      string += '<p>Some mortgage lenders will insist upon a full official Local Authority Search for conveyancing transactions, which may also result in additional fees or charges.</p>';
      string += '<p>Land Registry Fees are calculated and quoted as online submission to HMLR. If this solicitor or conveyancer is unable to perform online submission to HMLR OR this is a first registration for the property, then additional fees will apply.</p>';
      string += '<p>In such cases you will be informed in writing of the additional costs by your chosen solicitor or licensed conveyancer.</p>';
      string += '<p>All solicitors or licensed conveyancers who provide illustrations and quotations through The Conveyancing Index Website have signed a contract with iPlace Global Ltd to commit to providing the highest levels of service and transparent illustrations.</p>';
      string += '<p>We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: use of, or inability to use, our site; use of or reliance on any content displayed on our site; or use of the services or goods of any advertiser on our site.</p>';
      string += '<p>iPlace Global Ltd will ensure that any referral they make will be to an independent professional from whom you will receive impartial and confidential advice. We charge partner firms either a per lead fee or a flat referral fee for our promotion and marketing services.</p>';
      string += '<p>iPlace Global Ltd Limited, its officers, directors, employees, affiliates and agents, are indemnified by you from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of the Material or your breach of these Terms and Conditions.</p>';
      string += '</td></tr></table>';
      return string;
    },

  }
}
