
export const quoteHelpers = {
  computed: {
    quoteFeeDetails() {
      if (this.form) {
        let quotes = [];
        this.form.price_list.forEach(p => {

          let q = { id: p.id, firm: p.name, email: p.email };

          if (this.form.actions.includes('Sell') && this.form.sale_address && this.form.sale_price) {
            q.sale_fees = []

            q.sale_fees.push({ label: 'Sale Fee', value: this.calculateSaleFee(p, this.form.sale_price) })

            q.sale_fees.push(
                {
                  label: 'Other Disbursements (approximate)',
                  value: 150
                }
            )

            //add other fees here...
            if (this.form.sale_tenure === 'Leasehold') {
              q.sale_fees.push({ label: 'Leasehold Supplement', value: p.sale_leasehold })
            }
            if (this.form.sale_unregistered_land) {
              q.sale_fees.push({ label: 'Unregistered Land Supplement', value: p.sale_unregistered_land })
            }
            if (this.form.sale_freehold_with_management_co && this.form.sale_tenure === 'Freehold') {
              q.sale_fees.push({ label: 'Management Company Supplement', value: p.sale_freehold_with_management_co })
            }
            if (this.form.sale_equity_release_redemption) {
              q.sale_fees.push({ label: 'Equity Release Redemption Supplement', value: p.sale_equity_release_redemption })
            }
            if (this.form.sale_shared_ownership) {
              q.sale_fees.push({ label: 'Shared Ownership Supplement', value: p.sale_shared_ownership })
            }
            if (this.form.sale_auction_property) {
              q.sale_fees.push({ label: 'Auction Property Supplement', value: p.sale_auction_property })
            }
            if (this.form.sale_auction_attendance && this.form.sale_auction_property) {
              q.sale_fees.push({ label: 'Auction Attendance Supplement', value: p.sale_auction_attendance })
            }
            if (this.form.sale_subject_existing_ast) {
              q.sale_fees.push({ label: 'Sale subject to an existing AST Supplement', value: p.sale_subject_existing_ast })
            }
          }

          if (this.form.actions.includes('Buy') && this.form.purchase_address && this.form.purchase_price) {
            q.purchase_fees = [];

            q.purchase_fees.push({ label: 'Purchase Fee', value: this.calculatePurchaseFee(p, this.form.purchase_price) })

            q.purchase_fees.push(
                {
                  label: 'Search Pack (approximate)',
                  value: 300
                }
            )
            q.purchase_fees.push(
                {
                  label: 'Other Disbursements (approximate)',
                  value: 500
                },
            )

            //add other fees here...
            if (this.form.purchase_tenure === 'Leasehold') {
              q.purchase_fees.push({ label: 'Leasehold Supplement', value: p.purchase_leasehold })
            }
            if (this.form.purchase_freehold_with_management_co && this.form.purchase_tenure === 'Freehold') {
              q.purchase_fees.push({ label: 'Management Company Supplement', value: p.purchase_freehold_with_management_co })
            }
            if (this.form.purchase_shared_ownership) {
              q.purchase_fees.push({ label: 'Shared Ownership Supplement', value: p.purchase_shared_ownership })
            }
            if (this.form.purchase_new) {
              q.purchase_fees.push({ label: 'New Build Property Supplement', value: p.purchase_new })
            }
            if (this.form.purchase_gifted_deposit) {
              q.purchase_fees.push({ label: 'Gifted Deposit Supplement', value: p.purchase_gifted_deposit })
            }
            if (this.form.purchase_right_to_buy && !this.form.purchase_shared_ownership && !this.form.purchase_new && !this.form.purchase_htb) {
              q.purchase_fees.push({ label: 'Right to Buy Supplement', value: p.purchase_right_to_buy })
            }
            if (this.form.purchase_htb && !this.sale_exists) {
              q.purchase_fees.push({ label: 'Help to Buy Supplement', value: p.purchase_help_to_buy })
            }
            if (this.form.purchase_help_to_buy_isa && !this.sale_exists) {
              q.purchase_fees.push({ label: 'Help to Buy ISA Supplement', value: p.purchase_help_to_buy_isa })
            }

            if (this.form.purchase_unregistered_land && !this.form.purchase_shared_ownership && !this.form.purchase_new && !this.form.purchase_htb && !this.form.purchase_rtb) {
              q.purchase_fees.push({ label: 'Unregistered Land Supplement', value: p.purchase_unregistered_land })
            }

            if (this.form.purchase_auction_property && !this.form.purchase_shared_ownership && !this.form.purchase_new && !this.form.purchase_htb && !this.form.purchase_rtb) {
              q.purchase_fees.push({ label: 'Auction Property Supplement', value: p.purchase_auction_property })
            }
            if (this.form.purchase_auction_attendance && this.form.purchase_auction_property && !this.form.purchase_shared_ownership && !this.form.purchase_new && !this.form.purchase_htb && !this.form.purchase_rtb) {
              q.purchase_fees.push({ label: 'Auction Attendance Supplement', value: p.purchase_auction_attendance })
            }
          }

          q.total = this.itemTotal(q);
          q.vat = q.total * 0.20
          quotes.push(q);

        })
        return quotes;
      }
      return null;
    },

  },
  methods: {
    calculateSaleFee(firm_price_list,price){
      let fee = 0;
      if(price<=100000){
        fee = firm_price_list.sale_price_lte_100K;
      }
      else if(price<=200000){
        fee = firm_price_list.sale_price_lte_200K;
      }
      else if(price<=300000){
        fee = firm_price_list.sale_price_lte_300K;
      }
      else if(price<=400000){
        fee = firm_price_list.sale_price_lte_400K;
      }
      else if(price<=500000){
        fee = firm_price_list.sale_price_lte_500K;
      }
      else if(price<=750000){
        fee = firm_price_list.sale_price_lte_750K;
      }
      else if(price<=1000000){
        fee = firm_price_list.sale_price_lte_1000K;
      }
      else if(price>1000000){
        fee = firm_price_list.sale_price_gt_1000K;
      }
      return fee;
    },
    calculatePurchaseFee(firm_price_list,price){
      let fee = 0;
      if(price<=100000){
        fee = firm_price_list.purchase_price_lte_100K;
      }
      else if(price<=200000){
        fee = firm_price_list.purchase_price_lte_200K;
      }
      else if(price<=300000){
        fee = firm_price_list.purchase_price_lte_300K;
      }
      else if(price<=400000){
        fee = firm_price_list.purchase_price_lte_400K;
      }
      else if(price<=500000){
        fee = firm_price_list.purchase_price_lte_500K;
      }
      else if(price<=750000){
        fee = firm_price_list.purchase_price_lte_750K;
      }
      else if(price<=1000000){
        fee = firm_price_list.purchase_price_lte_1000K;
      }
      else if(price>1000000){
        fee = firm_price_list.purchase_price_gt_1000K;
      }
      return fee;
    },
    itemTotal(item) {
      let value = 0;
      if (item.sale_fees) {
        value += item.sale_fees.reduce((partialSum, a) => partialSum + a.value, 0);
      }
      if (item.purchase_fees) {
        value += item.purchase_fees.reduce((partialSum, a) => partialSum + a.value, 0);
      }
      return value;
    },


  }
}
