<template>
  <div class="fieldset">

    <template v-if="form.actions.includes('Sell')">
      <h3 class="fieldset-heading">Sale Details</h3>

        <SaleAddress
            v-model="form.sale_address"
            :form="form"
        />

        <SalePrice
            v-model="form.sale_price"
        />

        <SaleTenure
            v-model="form.sale_tenure"
        />

        <SaleUnregisteredLandYN
            v-model="form.sale_unregistered_land"
        />

        <SaleFreeholdWithManagementCoYN  v-if="form.sale_tenure=='Freehold'"
            v-model="form.sale_freehold_with_management_co"
        />

        <SaleEquityReleaseRedemptionYN
            v-model="form.sale_equity_release_redemption"
        />

        <SaleSharedOwnershipYN
            v-model="form.sale_shared_ownership"
        />

        <SaleAuctionPropertyYN
            v-model="form.sale_auction_property"
        />

        <SaleAuctionAttendanceYN v-if="form.sale_auction_property"
            v-model="form.sale_auction_attendance"
        />

        <SaleSubjectExistingAstYN
            v-model="form.sale_subject_existing_ast"
        />

    </template>
    <template v-if="form.actions.includes('Buy')">
      <h3 class="fieldset-heading">Purchase Details</h3>

        <PurchaseAddress
            v-model="form.purchase_address"
            :form="form"
        />

        <PurchasePrice
            v-model="form.purchase_price"
        />

        <PurchaseTenure
            v-model="form.purchase_tenure"
        />

        <PurchaseNewYN
            v-model="form.purchase_new"
        />

        <PurchaseHtbYN v-if="form.purchase_new && !form.actions.includes('Sell')"
            v-model="form.purchase_htb"
        />

        <PurchaseSharedOwnershipYN
            v-model="form.purchase_shared_ownership"
        />

        <PurchaseGiftedDepositYN
            v-model="form.purchase_gifted_deposit"
        />

        <PurchaseRightToBuyYN v-if="!form.purchase_shared_ownership && !form.purchase_new && !form.purchase_htb"
            v-model="form.purchase_right_to_buy"
        />

        <PurchaseHelpToBuyIsaYN v-if="!form.actions.includes('Sell')"
            v-model="form.purchase_help_to_buy_isa"
        />

        <PurchaseUnregisteredLandYN v-if="!form.purchase_shared_ownership && !form.purchase_new && !form.purchase_htb && !form.purchase_rtb"
            v-model="form.purchase_unregistered_land"
        />

        <PurchaseFreeholdWithManagementCoYN v-if="form.purchase_tenure=='Freehold'"
            v-model="form.purchase_freehold_with_management_co"
        />

        <PurchaseAuctionPropertyYN  v-if="!form.purchase_shared_ownership && !form.purchase_new && !form.purchase_htb && !form.purchase_rtb"
            v-model="form.purchase_auction_property"
        />

        <!-- OK if auction -->
        <PurchaseAuctionAttendanceYN v-if="!form.purchase_shared_ownership && !form.purchase_new && !form.purchase_htb && !form.purchase_rtb && form.purchase_auction_property"
            v-model="form.purchase_auction_attendance"
        />

    </template>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import SaleAddress from "@/components/common/questionnaires/question/questions/conveyance/sale/Address";
import SalePrice from "@/components/common/questionnaires/question/questions/conveyance/sale/Price";
import SaleTenure from "@/components/common/questionnaires/question/questions/conveyance/sale/Tenure";
import SaleUnregisteredLandYN from "@/components/common/questionnaires/question/questions/conveyance/sale/UnregisteredLandYN";
import SaleFreeholdWithManagementCoYN from "@/components/common/questionnaires/question/questions/conveyance/sale/FreeholdWithManagementCoYN";
import SaleEquityReleaseRedemptionYN from "@/components/common/questionnaires/question/questions/conveyance/sale/EquityReleaseRedemptionYN";
import SaleSharedOwnershipYN from "@/components/common/questionnaires/question/questions/conveyance/sale/SharedOwnershipYN";
import SaleAuctionPropertyYN from "@/components/common/questionnaires/question/questions/conveyance/sale/AuctionPropertyYN";
import SaleAuctionAttendanceYN from "@/components/common/questionnaires/question/questions/conveyance/sale/AuctionAttendanceYN";
import SaleSubjectExistingAstYN from "@/components/common/questionnaires/question/questions/conveyance/sale/SubjectExistingAstYN";

import PurchaseAddress from "@/components/common/questionnaires/question/questions/conveyance/purchase/Address";
import PurchasePrice from "@/components/common/questionnaires/question/questions/conveyance/purchase/Price";
import PurchaseTenure from "@/components/common/questionnaires/question/questions/conveyance/purchase/Tenure";
import PurchaseNewYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/NewYN";
import PurchaseHtbYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/HtbYN";
import PurchaseFreeholdWithManagementCoYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/FreeholdWithManagementCoYN";
import PurchaseUnregisteredLandYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/UnregisteredLandYN";
import PurchaseSharedOwnershipYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/SharedOwnershipYN";
import PurchaseGiftedDepositYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/GiftedDepositYN";
import PurchaseAuctionPropertyYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/AuctionPropertyYN";
import PurchaseAuctionAttendanceYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/AuctionAttendanceYN";
import PurchaseRightToBuyYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/RightToBuyYN";
import PurchaseHelpToBuyIsaYN from "@/components/common/questionnaires/question/questions/conveyance/purchase/HelpToBuyIsaYN";

export default {
  name: 'Options',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    SaleAddress,
    SalePrice,
    SaleTenure,
    SaleUnregisteredLandYN,
    SaleFreeholdWithManagementCoYN,
    SaleEquityReleaseRedemptionYN,
    SaleSharedOwnershipYN,
    SaleAuctionPropertyYN,
    SaleAuctionAttendanceYN,
    SaleSubjectExistingAstYN,
    PurchaseAddress,
    PurchasePrice,
    PurchaseTenure,
    PurchaseNewYN,
    PurchaseHtbYN,
    PurchaseFreeholdWithManagementCoYN,
    PurchaseUnregisteredLandYN,
    PurchaseSharedOwnershipYN,
    PurchaseGiftedDepositYN,
    PurchaseAuctionPropertyYN,
    PurchaseAuctionAttendanceYN,
    PurchaseRightToBuyYN,
    PurchaseHelpToBuyIsaYN,
  },
  data() {
    return {
      label: 'Details', // used for steps progress
      disableStepsNavigation: false,
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
  },
};
</script>
