<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Estimates</h3>

    <div v-if="quoteFeeDetails.length">

<b-card v-for="(item,index) in quoteFeeDetails"
        :key="index"
        class="mb-30 rounded-lg o-hidden"
        no-body>
  <b-card-header
                header-tag="header"
                class="bg-primary pb-0"
                role="tab">
    <div class="d-flex align-items-center">
      <b-button v-b-toggle="`accordion${index}`"
                block
                class="card-title mb-0 pl-0"
                href="#"
                variant="transparent">
        <strong class="text-white">{{item.firm}}</strong>
      </b-button>

      <b-button v-b-toggle="`accordion${index}`"
                href="#"
                class="text-center w-25"
                variant="secondary">
        View Estimate
      </b-button>

    </div>
    <div class="pt-4 pb-0">
      <table class="table table-condensed price_table text-white">
        <tr>
          <th class="text-center">Legal Fees</th>
          <th class="text-center">VAT</th>
          <th class="text-center">Estimate Total</th>
        </tr>
        <tr>
          <td class="text-center">£{{item.total.toFixed(0)}}</td>
          <td class="text-center">£{{item.vat.toFixed(0)}}</td>
          <td class="text-center font-weight-bold">£{{(item.total+item.vat).toFixed(0)}}</td>
        </tr>
      </table>
    </div>
  </b-card-header>

  <b-collapse :id="`accordion${index}`"
              accordion="quote-accordion"
              class="text-left"
              role="tabpanel">
    <b-card-body>
      <div class="pt-20 pl-20 pr-20">
        <b-row>
          <b-col md="12">
            <div class="pr-20 pb-20">

              <template v-if="form.actions.includes('Sell')">
                <h5>SALE FEES</h5>
                <table class="table table-condensed w-100">
                  <tr v-for="(fee,index2) in item.sale_fees" :key="'sale'+index+'_'+index2">
                    <td>{{fee.label}}</td>
                    <td class="text-right">£{{fee.value}}</td>
                  </tr>
                </table>
              </template>

              <template v-if="form.actions.includes('Buy')">
                <h5 class="pt-2">PURCHASE FEES</h5>
                <table class="table table-condensed w-100">
                  <tr v-for="(fee,index3) in item.purchase_fees" :key="'purchase'+index+'_'+index3">
                    <td>{{fee.label}}</td>
                    <td class="text-right">£{{fee.value}}</td>
                  </tr>
                </table>
              </template>

              <template v-if="form.actions.includes('Sell')||form.actions.includes('Buy')">
                <h5 class="pt-2">TOTAL</h5>
                <table class="table table-condensed w-100">
                  <tr style="border-top:#ddd 1px solid">
                    <td>Legal Fees</td>
                    <td class="text-right">£{{item.total}}</td>
                  </tr>
                  <tr style="border-top:#ddd 1px solid">
                    <td>VAT</td>
                    <td class="text-right">£{{item.vat}}</td>
                  </tr>
                  <tr style="border-top:#ddd 1px solid">
                    <th>Estimate Total</th>
                    <th class="text-right">£{{item.total+item.vat}}</th>
                  </tr>
                </table>
              </template>

<!--              <b-button href="#"-->
<!--                        class="text-center mb-24 w-100"-->
<!--                        variant="secondary"-->
<!--                        @click="downloadQuote(item)"-->
<!--                        >-->
<!--                Download Estimate-->
<!--              </b-button>-->

            </div>
          </b-col>

        </b-row>
      </div>
    </b-card-body>
  </b-collapse>
</b-card>

</div>

    <Conveyancer v-if="quoteFeeDetails.length>1"
        v-model="form.firm"
        :errors-post="errorsPost"
        :optionsData="quoteFeeDetails"
        :no-save="false"
    />


  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {quoteHelpers} from "@/views/questionnaires/Conveyance/quoteHelpers";
import Conveyancer from "@/components/common/questionnaires/question/questions/conveyance/Conveyancer";

export default {
  name: 'Quote',
  mixins: [fieldsetHelpers, quoteHelpers],
  components: {
    Conveyancer,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      label: 'Quotes', // used for steps progress
      disableStepsNavigation: false,
      proceed:null,
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    sale_exists(){
      if(this.form && this.form.actions.includes('Sell')){
        return true;
      }
      return false
    },

  },
  methods:{
    downloadQuote(item) {

      const form={
        url:`conveyance_quote_summary_pdf?code=${this.$route.params.code}`,
        quoteDetails:this.form,
        selected_quote:item,
      }

      this.$emit("direct_download",form);

    },
    
    refreshDocuments() {
      this.$emit("refreshDocuments");
    },
  },
};
</script>
<style scoped>
.price_table > tr > td{
  border:none;
  font-size: x-large;
  padding:0.1rem;
}
.price_table > tr > th{
  border:none;
  font-size: x-small;
  padding:0.1rem;
}
</style>