<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"
                :valid="!!data.length">
    <transition name="fade">
      <div class="accordion sub-items">
        <div class="question-box with-arrow mt-32">
          <ObjectCard v-for="(person, index) in data"
                      :id="'person'+index"
                      :key="'person' + person.id"
                      :ref="'person'+index"
                      v-model="data[index]"
                      :title="`${person.first_name} ${person.last_name}`"
                      type="person"
                      :loading="loading"
                      @delete="deleteObject(person.id)"
                      @save="saveObject(person, 'person'+index)">
            <Component :is="'SellerFull'"
                       :key="'person-form' + person.id"
                       v-model="data[index]"
                       :objectId="person.id"/>
          </ObjectCard>

          <ObjectCard v-if="form"
                      ref="person-add"
                      v-model="form"
                      title="New Seller"
                      type="person-add"
                      :loading="loading"
                      @delete="form=null"
                      @save="saveObject(form, db.saveField)">
            <Component :is="'SellerFull'"
                       v-model="form"
                       :errors-post="errorsPost"
                       :noSave="true"/>
          </ObjectCard>

          <b-button v-if="!form"
                    class="btn-question w-100"
                    @click="add">
            <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Seller
          </b-button>
        </div>
      </div>
    </transition>
  </QuestionBase>
</template>
<script>
import {objectSaveHelpers} from "../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import {blankForms} from "../../helpers/blankForms";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";
import SellerFull from "@/components/common/questionnaires/fieldsets/sub/conveyance/SellerFull";

export default {
  name: 'Sellers',
  components: {
    SellerFull,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    objectSaveHelpers,
    questionLoadHelpers,
    blankForms
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: 'full'
    },
  },
  data() {
    return {
      question: {
        title: 'Who currently owns the property?',
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: `seller?code=${this.$route.params.code}`,
        saveField: 'seller',
        deleteLocation: 'conveyance_quote_person',
        loadLocation: 'seller',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: true
      },
      args: {
        skippable: true,
        required: false,
        customErrorMessageNewOpenForm: "Save before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        if (this.value) {
          return this.value;
        }
        return [];
      }
    },
  },
  methods: {
    add() {
      this.form = null
      this.form = Object.assign({}, this.conveyanceSaleBlankForm)
    }
  }
}
</script>
