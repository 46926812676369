<template>
  <div>

    <FullName
        :noSave="noSave"
        :objectId="objectId"
        :value-first="form.first_name"
        :value-middle="form.middle_name"
        :value-last="form.last_name"
        :errors-post-first="errorsPost.first_name || []"
        :errors-post-middle="errorsPost.middle_name || []"
        :errors-post-last="errorsPost.last_name || []"
        @inputFirst="form.first_name=$event"
        @inputMiddle="form.middle_name=$event"
        @inputLast="form.last_name=$event"
    />

    <ContactInformation :value-first="form.email"
              :value-second="form.phone_number"
              @inputFirst="form.email=$event"
              @inputSecond="form.phone_number=$event"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Address
        v-model="form.address"
        :form="form"
        :noSave="true"
        :objectId="form.address && form.address.id ? form.address.id : null"
        :errors-post="errorsPost.address || []"
    />

  </div>
</template>

<script>
import FullName from "@/components/common/questionnaires/question/questions/conveyance/person/FullName";
import ContactInformation from "@/components/common/questionnaires/question/questions/conveyance/person/ContactInformation";
import Address from "@/components/common/questionnaires/question/questions/conveyance/person/Address";


export default {
  name: 'BuyerFull',
  components: {
    FullName,
    ContactInformation,
    Address,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
