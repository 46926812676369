<template>
  <QuestionBase :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
                :question="question"
                :valid="!!data">
    <InputBasic v-model="data"
                :args="args"
                :db="db"
                :noSave="noSave"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputBasic from "@/components/common/questionnaires/question/inputs/InputBasic";

export default {
  name: 'MortgageOutstand',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: [
        Number,
        String
      ],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'How much will you be borrowing?',
        subTitle: '',
        tip: null
      },
      db: {
        saveLocation: `conveyance_quote?code=${this.$route.params.code}`,
        saveField: 'purchase_mortgage_outstand',
      },
      args: {
        placeholder: 'e.g. 200000',
        label: null,
        type: 'number',
        skippable: false,
        required: true,
        inputClass: 'field-66',
        prependText: '£'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>